body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
  .container {
    width: 80%;
    margin: 0 auto;
  }
  
  header {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header h1 {
    margin: 0;
    padding: 0 20px;
  }
  
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  nav ul li {
    margin: 0 10px;
  }
  
  nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  main {
    padding: 20px 0;
  }
  
  h2 {
    color: #333;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }
  
  .deck-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .deck-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .deck-card img {
    max-width: 100%;
    height: auto;
  }
  
  footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
  }
  
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  footer ul li {
    margin: 0 10px;
  }
  
  footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  